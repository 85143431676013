@use '../../atoms/atoms';
.PriceCard {
    @include atoms.flex(row, wrap, space-between, flex-start, $display: inline-flex);
    background-color: atoms.color("secondary", "dark");
    @include atoms.card-shadow();
    max-width: 640px;
    padding: 16px; //Technically this puts it out of alignment with all the other content on the page, but it looks terrible otherwise. Just following the design here ¯\_(ツ)_/¯
    &>* {
        min-width: 23ch; width: min-content; max-width: 100%;
        min-height: 12ch; height: min-content; max-height: 100%;
        margin: 16px;
    }
    &-features {
        list-style: disc;
        list-style-position: inside;
        &-item {
            &.h3--bold {
                //List header
                list-style: none;
                margin-left: 0;
            }
        }
    }
    &-pricing {
        &-price {
            line-height: 0.9;
        }
    }
    @media screen and (max-width: 1280px) {
        & {
            margin-bottom: 32px;
        }
    }
    // @media screen and (max-width: 768px) {
    //     & {
    //         flex-direction: column;
    //     }
    // }
}
@use './reset';
@use './stories/atoms/icons';
@use './stories/atoms/types';
@use './stories/atoms/atoms';

body {
    padding-top: 3.5rem; //Needed if the navbar is gonna be position: fixed or position: absolute, since it wouldn't be part of the page flow and therefore won't push content down on its own. 
    background-color: atoms.color("neutral","dark");
}
//This is all standardized spacing. The relative vh values are approximated based on absolute px values from the design system. 12vh / 100vh ~= 128px / 1080px
header {
    padding-top: 12vh;
}
main {
    padding-top: 6vh;
    padding-bottom: 12vh;
    min-height: 60vh; //Just making sure the footer gets pushed down
}
.container {
    width: 1280px; max-width: 100%;
    margin: 0 auto;
}
@media screen and (max-width: 1280px) {
    main, header {
        padding-left: 16px;
        padding-right: 16px;
    }
}

article {
    h2,h3 {
        margin-bottom: 0.35em;
    }
    p {
        margin-bottom: 1em;
    }
}
hr {
    color: atoms.color("neutralAlpha","light");
}
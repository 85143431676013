@use '../../atoms/atoms';
// Padding and line-height are used
// to articifially inflate the size of
// the dropdown hover target area
$left-offset: 16px;
.Dropdown {
    position: relative;
    @include atoms.flex(row, nowrap, flex-start);
    cursor: pointer;
    &-label {
        padding: 0 $left-offset;
        line-height: 2.5rem;
        white-space: nowrap;
        .icon {
            display: inline-block;
            transition: 0.25s ease transform;
            vertical-align: -15%;
        }
    }
    &, &-menu {
        display: block;
    }
    &-menu {
        padding: 0.5em 0;
        background-color: atoms.color("secondary", "dark");
        transition-duration: 0.25s;
        transition-timing-function: ease;
        list-style: none;
        &-item {
            white-space: nowrap;
            padding: 0.5em $left-offset;
        }
    }
}
@media screen and (min-width: 1280px) {
    //TODO move this to Nav.scss to control its behavior modularly?
    .Dropdown {
        &-menu {
            z-index: -1;
            position: absolute;
            display: inline-block;
            @include atoms.card-shadow();
            /* Transition Properties
            Number of items x height of each item negative offset  */
            top: -1 * 4 * 2rem; left: 0;
            opacity: 0;
            transition-property: top, opacity;
        }
        &:hover {
            &>.Dropdown-menu {
                top: 3rem;
                opacity: 1;
            }
            &>.Dropdown-label>.icon {
                transform: rotateX(180deg);
            }
        }
    }
}
@media screen and (max-width: 1280px) {
    //TODO This should be a global class maybe??
    .Dropdown {
        &.expandable {
            &>.Dropdown-menu {
                @include atoms.expandable();
            }
        }
        &.expanded, &:focus-within { 
            & .icon {
                transform: rotateX(180deg);
            }
            &>.Dropdown-menu {
                //4 lines maximum height for menus
                max-height: 4 * 1.35 * 2rem;
                padding: 0.5rem 0;
            }
        }
    }
}
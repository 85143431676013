@use './colors' as atoms; //TODO This is hacky. At some point, someone needs to figure out how exactly the fuck you're supposed to properly structure a style library in Sass, because I couldn't figure it.
@mixin button-shadow($offset: 4px) {
    box-shadow: 0 $offset atoms.color("blue", "regular")
}
@mixin card-shadow($offset: 4px, $blur: 16px) {
    box-shadow: ($offset * -1) $offset $blur atoms.color("neutralAlpha", "light")
}
@mixin expandable {
    max-height: 0;
    overflow: hidden;
    padding: 0;
    transition-duration: 0.25s;
    transition-property: max-height, padding;
    transition-timing-function: ease-in-out;
}
@mixin reveal {
    @keyframes reveal {
        0% {
            opacity: 0;
            pointer-events: none;
        }
        100% {
            opacity: 1;
        }
    }
}
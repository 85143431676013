@use '../../atoms/spacing' as s;
@use '../../atoms/atoms';
.Modal {
    &, &-splash {
        position: fixed;
        top: 0; left: 0;
        width: 100vw; height: 100vh;
    }
    z-index: 4; //1 greater than nav
    @include atoms.flex(row, nowrap);
    background-color: atoms.color("neutralAlpha", "regular");
}
@use '../../atoms/atoms';
.ServerStatusCard-table {
    width: 100%; max-width: 100%;
    table-layout: fixed;
    border-collapse: collapse;
    th, td {
        padding: 0.5em;
    }
    tr:nth-child(even) {
        background-color: atoms.color("neutralAlpha","light");
    }
}
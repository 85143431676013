@use '../../atoms/atoms';

.ModalCard {
    position: relative;
    min-width: 256px; width: 768px; max-width: 100%;
    min-height: 360px; height: auto; max-height: 100vh;
    // @include atoms.flex(column, nowrap, flex-start, flex-start);
    padding: 64px;
    background-color: atoms.color("secondary", "dark");
    &-header {
        &-title {
            color: atoms.color("text","dark");
        }
        &-close {
            cursor: pointer;
            position: absolute;
            top: 6vh; right: 6vh;
            line-height: 1ch;
            color: atoms.color("text", "regular");
        }
    }
    &-body {
        position: relative;
        @include atoms.flex(column, nowrap, space-between, flex-start);
        margin-top: 16px;
        & form>* {
            margin: 8px 0;
        }
        .input-item--checkbox {
            display: inline;
        }
    }
    &-footer {
        @include atoms.flex(row, wrap, flex-start, flex-start);
        margin-top: 16px;
        .Button:first-child {
            margin-right: 1ch;
        }
    }
    &-error {
        // position: absolute;
        // top: 0; left: 0;
        // width: 100%; height: 100%;
        padding: 2em 0;
        margin: 1em 0;
        @include atoms.flex(column, wrap);
        background-color: atoms.color("neutralAlpha", "light");
        &-message {
            width: 66%;
        }
    }
    &.loading > .LoadingScreen {
        //While loading, make it opaque and prevent clicking behind it
        //This isn't ideal, but it's good enough I guess
        opacity: 1;
        pointer-events: all;
    }
    @media screen and (max-width: 768px) {
        & {
            padding: 32px;
        }
    }
}
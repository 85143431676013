@use "../atoms/atoms";
.Dashboard {
    .Card {
        width: 100%; max-width: 100%;
        margin: 16px 0;
    }
    &-card-content {
        @include atoms.flex(column, nowrap, space-evenly, flex-start);
        &>* {
            margin: 8px 0;
        }
    }
    &-container {
        &--button {
            @include atoms.flex(row, wrap, flex-start);
            .Button {
                margin: 16px 16px 0 0;
            }
        }
        &--link {
            a {
                display: block;
            }
        }
    }
}
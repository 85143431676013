@use '../../atoms/atoms';

@keyframes expand {
    0% {
        max-width: 0.5em;
    }
    100% {
        max-width: 9ch;
    }
}
$null-width: 2.14em;
$black-width: 2.71em;
//1em = 36px, 1ch = 24.7833px     1 - 0.688425
$delay: 0s;
@mixin abspos ($t,$b,$l,$r) {
    top: $t;
    bottom: $b;
    left: $l;
    right: $r;
}
//relpos (y, x) Relative to original position.
//I don't actually know if this is less computationally intense. I'm guessing it's at least slightly more intense because it has to do a relative calculation instead of an absolute one. The only reason I did it is because I was hoping it would allow me to transition smoothly in and out of the animation, but it doesn't seem to be working. Might change it back. For now, I've spent way too much time on this.
@mixin relpos ($t: 0,$l: 0) {
    top: $t;
    left: $l;
}
@keyframes n { //This is called this because "animation-name: null" is interpreted as a null value lol
    0% {
        //top left
        // @include abspos(0,50%,0,$n-x-offset);
        @include relpos(-50%, 0);
    }
    25% {
        //top right
        // @include abspos(0,50%,$n-x-offset,0);
        @include relpos(-50%, $black-width);
    }
    50% {
        //bottom right
        // @include abspos(50%,0,$n-x-offset,0);
        @include relpos(50%, $black-width);
    }
    75% {
        //bottom left
        // @include abspos(50%,0,0,$n-x-offset);
        @include relpos(50%, 0);
    }
    100% {
        //top left again
        // @include abspos(0,50%,0,$n-x-offset);
        @include relpos(-50%, 0);
    }
}
@keyframes b {
    0% {
        //top right
        // @include abspos(0,50%,$b-x-offset,0);
        @include relpos(-50%, 0);
    }
    25% {
        //bottom right
        // @include abspos(50%,0,$b-x-offset,0);
        @include relpos(50%, 0);
    }
    50% {
        //bottom left
        // @include abspos(50%,0,0,$b-x-offset);
        @include relpos(50%, (-1 * $null-width));
    }
    75% {
        //top left
        // @include abspos(0,50%,0,$b-x-offset);
        @include relpos(-50%, (-1 * $null-width));
    }
    100% {
        //top right again
        // @include abspos(0,50%,$b-x-offset,0);
        @include relpos(-50%, 0);
    }
}
@keyframes piston {
    0% {
        top: 50%;
    }
    100% {
        top: -50%;
    }
}
.Logo {
    &-box {
        width: 7em; height: 7em;
        @include atoms.flex(row, nowrap);
    }
    &-fill {
        background-color: atoms.color("secondary","regular");
    }
    &-text {
        line-height: 1.1;
        color: atoms.color("primary","light");
        &::before {
            content: "[";
        }
        &::after {
            content: "]";
        }
        .Logo-name, &::before, &::after {
            position: relative;
        }
        .Logo-name {
            display: inline-block;
        }
        &-expand {
            .Logo-name {
                vertical-align: top;
                overflow: hidden;
                max-width: 1ch;
                animation: expand 0.25s cubic-bezier(.77,0,.18,1) 1s 1 normal forwards;
            }
        }
        &-animate {
            .Logo-name, &::before, &::after {
                animation-timing-function: ease-in-out;
                animation-iteration-count: infinite;
                animation-fill-mode: both;
                animation-delay: $delay;
            }
            &::before,&::after {
                animation-name: piston;
                animation-duration: 0.5s;
                animation-direction: alternate-reverse;
            }
            &::before {
                animation-delay: $delay + 0.5s;
            }
            .Logo-name {
                & {
                    animation-direction: normal;
                    animation-duration: 2s;
                }
                &.null {
                    animation-name: n;
                    animation-delay: $delay + 0.5s;
                }
                &.black {
                    animation-name: b;
                }
            }
        }
    }
}
@use '../../atoms/atoms';
.Input {
    display: block;
    .input-item {
        margin: 4px 0;
        padding: 0.5em 1em;
        border: none;
        outline: none;
        background-color: atoms.color("secondary", "regular");
        transition: 0.25s ease background-color;
        &--text, &--email, &--password  {
            @extend .input-item;
            color: atoms.color("text", "dark");
            min-width: 20em;
            
            //On text entry
            &:not([readonly]):not(:disabled) {
                &:focus {
                    transition-property: background-color, box-shadow;
                    @include atoms.card-shadow();
                    background-color: atoms.color("secondary", "light");
                    //Hide placeholder text
                    &::placeholder {
                        color: transparent;
                    }
                }
            }

            //Default text styling
            &::placeholder {
                color: atoms.color("text", "light");
            }
        }
        &--select {
            @extend .input-item;
            color: atoms.color("text","dark");
        }
        &--checkbox {
            margin-right: 1ch;
            &, & + .text--secondary {
                display: inline;
                vertical-align: middle;
            }
        }
        &:disabled, &:read-only {
            opacity: 0.6; //De-emphasize for read-only values
            cursor: not-allowed;
        }
    }
}
@use '../../atoms/atoms';
@use 'sass:math';
.Dial {
    $shadow-color: atoms.color("neutralAlpha", "regular");
    position: relative;
    display: inline-block;
    width: 16rem; max-width: 100%;
    height: 16rem; max-height: 100%;
    border-radius: 50%;
    background-color: atoms.color("secondary", "regular");
    box-shadow: inset 0 0 16px 4px $shadow-color;
    &-counter {
        position: absolute;
        bottom: 55%; left: 0; right: 0;
        text-align: center;
        &-value, &-unit {
            padding: 0 0.15ch;
        }
        &-value {
	    font-family: monospace;
            background-image: linear-gradient(180deg, $shadow-color 0%, transparent 30%, transparent 70%, $shadow-color 100%);
        }
    }
    &-face-label {
        position: absolute;
        top: 55%; left: 0; right: 0;
        color: atoms.color("text", "light");
        text-align: center;
    }
    &-hand {
        position: absolute;
        width: 2px; height: 40%;
        top: 10%; left: calc(50% - 2px);
        transform-origin: 100% 100%;
        border-right: 2px solid white;
	//if the animation perfectly matches the rate of updates, it'll cause weird jitter beacuse the rotation gets updated in the middle of the animation
        transition: .04s linear transform; //switch to linear if we can solve that weird jitter issue and make duration = update period
    }
    &-numbers {
	    background: no-repeat center/cover url("dial.svg");
    }
    //TODO Dear lord, this is hacky as hell, but I really didn't want to go and make my own SVG graphics for this. That's probably a much better approach though, so do that in the future.
    // &-numbers {
    //     width: 100%; height: 100%;
    //     counter-reset: numbers -1;
    // }
    // &-number {
    //     position: absolute;
    //     display: block;
    //     counter-increment: numbers;
    //     &::before {
    //         content: counter(numbers);
    //     }
    //     @for $i from 1 to 10 {
    //         &:nth-of-type(#{$i}) {
    //             top: 50%; left: 50%;
    //             transform-origin: bottom center;
    //             transform: rotate(math.div(360deg, 10) * $i)
    //             translate(-128px)
    //             ;
    //         }
    //     }
    // }
    @media screen and (max-width: 1000px) {
        & {
            max-width: 90vw;
            max-height: 90vw;
        }
        &:not(:last-child) {
            margin-bottom: 16px;
        }
    }
}

@use '../../atoms/atoms';
.Button {
    color: atoms.color("text", "on-primary");
    padding: 0.5rem 1rem;
    @include atoms.flex(row, nowrap, space-evenly, $display: inline-flex);
    border: none;
    /* Type nonsense */
    font-size: 1rem;
    
    /* Transition properties*/
    @include atoms.button-shadow();
    background-color: atoms.color("primary", "light");
    transition-duration: 0.15s;
    transition-timing-function: ease;
    transition-property: background-color, box-shadow, transform;
    
    &:not(:disabled) {
        &:hover {
            background-color: atoms.color("primary", "regular");
            cursor: pointer;
        }
        &:active {
            $offset: 2px;
            transform: translateY(2 * $offset);
            @include atoms.button-shadow($offset);
        }
    }
    &:disabled {
        cursor: not-allowed;
    }
    &-icon {
        letter-spacing: 8px;
        font-size: 1.2rem;
    }
}
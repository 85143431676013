.Privacy {
    counter-reset: group;
    h2,h3 {
        display: inline-block;
    }
    &-group {
        counter-increment: group; //TODO This counter has to basically be hardcoded in so that it's available to &-section. Honestly this whole thing probably could use a refactor, I'm just not 100% sure how these counters work. https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_Lists_and_Counters/Using_CSS_counters
        &::marker {
            content: counter(group) ". ";
        }
    }
    &-sections {
        counter-reset: item;
        padding-left: 1ch;
    }
    &-section {
        counter-increment: item;
        &::marker {
            content: counter(group) "." counter(item) " ";
        }
    }
}
@use '../../atoms/atoms';
.Card {
    position: relative;
	box-sizing: border-box;
    @include atoms.card-shadow();
    background-color: atoms.color("secondary", "dark");
    @include atoms.flex(column, nowrap, space-between, flex-start);
    width: 100%; 
    &-header, &-body {
        padding: 16px;
    }
    &-header {
        width: 100%; height: 4rem;
        @include atoms.flex(row, nowrap, left);
        white-space: nowrap;
        // white-people: norap;
        background-color: atoms.color("secondary", "regular");
        &-icon { //Make this a reuse class, since buttons use same code?
            font-size: 1.75rem;
            margin-right: 0.33em;
        }
    }
    &-body {
        &-content {
            //I honestly cannot explain how this algorithm works
            //in English, all I know is that it does.
            //https://developer.mozilla.org/en-US/docs/Web/CSS/width

            //Make content as narrow as possible, down to 30ch
            min-width: 32ch;
            //Make content as short as possible, down to 9 lines of text
            min-height: (9 * 1em * 1.35); height: min-content; max-height: 100vh;
        }
        &-footer {
            @include atoms.flex(row, nowrap);
            margin: 16px 0;
        }
    }
    @media screen and (max-width: 1280px) {
        & {
            width: 100%;
            &-body-content {
                min-width: 20ch;
            }
        }
        &:not(:last-child) {
            margin-bottom: 32px;
        }
    }
}
@use '../../atoms/atoms';
.DownloadCard {
    @include atoms.card-shadow();
    background-color: atoms.color("secondary","dark");
    .icon {
        font-size: 1.5em;
        vertical-align: -20%;
        margin-right: 0.5ch;
        color: atoms.color("text","dark");
    }
    .download-text {
        margin-right: 1ch;
    }
}

.Downloads {
    table-layout: fixed;
    border-collapse: separate;
    border-spacing: 0 1rem;
    width: 100%;
    caption, th, td {
        text-align: left;
    }
    th {
        color: atoms.color("text","light");
        padding: 0 1em;
    }
    td {
        padding: 1.5em 1em;
    }
}
@media screen and (max-width: 1280px) {
    .DownloadCard {
        .platform-name, .download-text {
            display: none;
        }
    }
    .Downloads {
        th, td {
            text-align: center;
        }
    }
}
@use '../../atoms/atoms';
.Footer {
    background-color: atoms.color("secondary", "dark");
    padding: 32px 16px;
    @include atoms.flex(row, wrap, flex-start);
    &-content {
        @include atoms.flex(row, wrap, flex-start);
    }
    .Logo {
        font-size: 1.75rem;
        @include atoms.card-shadow();
    }
    &-info {
        flex-grow: 1;
        font-style: normal;
        color: atoms.color("text","light");
        &-title {
            color: atoms.color("text","dark");
        }
        &-title, &-description {
            display: inline;
        }
        &-list {
            padding-left: 0;
            display: inline;
            list-style: none;
            &-item {
                display: inline;
            }
        }
    }
    @media screen and (min-width: 1920px) {
        &-content {
            position: relative;
            //This is the size of the logo
            min-height: (7 * 1.75rem);
        }
        .Logo {
            //This is a bit hacky, but removing the logo from page flow was the best simple and maintainable solution I could come up with to prevent it from fucking up the content alignment
            position: absolute;
            top: 0; right: calc(100% + 16px);
        }
    }
    @media screen and (max-width: 1920px) {
        .Logo {
            margin-right: 16px;
        }
    }
    @media screen and (max-width: 700px) {
        &-info {
            padding-top: 16px;
        }
    }
}
@use '../atoms/atoms';
.Home {
    &-header {
        position: relative;
        //100vh - nav height, header top padding, and height and top padding of the header of the next section below
        min-height: calc(77vh - 3.5rem);
        padding-bottom: 5vh;
        @include atoms.flex(column, nowrap);
        &-background {
            position: absolute;
            top: 0; left: 0;
            width: 100%; height: 100%;
            z-index: -1;
            object-position: center center;
            object-fit: cover;
            filter: opacity(10%) contrast(150%);
        }
        &-content {
            @include atoms.flex(column, nowrap);
            text-align: center;
            h1, a {
                color: atoms.color("text","opaque");
                margin-bottom: 16px; 
            }
        }
    }
    &-section {
        &:not(:last-of-type) {
            margin-bottom: 12vh;
        }
        .card-container {
            @include atoms.flex(row, wrap, space-between, center);
            padding: 32px 0;
        }
        @media screen and (min-width: 1281px) {
            &#features>.card-container>.Card {
                width: min-content;
            }
        }
        @media screen and (max-width: 1280px) {
            &#features>.card-container>.Card { max-width: 608px; }
            &#features>.card-container, &#pricing>.card-container {
                flex-direction: column;
            }
        }
        @media screen and (max-width: 1000px) {
            &#stats>.card-container {
                flex-direction: column;
            }
        }
    }
}
//Generated by icomoon - https://icomoon.io
$icomoon-font-family: "nullblack-icons" !default;
$icomoon-font-path: "../assets/icons" !default;

$icon-admin: "\e900";
$icon-apple: "\e901";
$icon-dollar: "\e902";
$icon-download: "\e903";
$icon-logout: "\e904";
$icon-paypal: "\e905";
$icon-server: "\e906";
$icon-share: "\e907";
$icon-team: "\e908";
$icon-ubuntu: "\e909";
$icon-windows: "\e90a";
$icon-android: "\e90b";
$icon-arrow-down: "\e90c";

@font-face {
  font-family: '#{$icomoon-font-family}';
  src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?yhcjex');
  src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?yhcjex#iefix') format('embedded-opentype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?yhcjex') format('truetype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?yhcjex') format('woff'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?yhcjex##{$icomoon-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

.icon {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-font-family}';
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1em;
  vertical-align: -4%; //This is a little magic-number-y, but whatever

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  &-admin::before {
    content: $icon-admin;
  }
  &-apple::before {
    content: $icon-apple;
  }
  &-dollar::before {
    content: $icon-dollar;
  }
  &-download::before {
    content: $icon-download;
  }
  &-logout::before {
    content: $icon-logout;
  }
  &-paypal::before {
    content: $icon-paypal;
  }
  &-server::before {
    content: $icon-server;
  }
  &-share::before {
    content: $icon-share;
  }
  &-team::before {
    content: $icon-team;
  }
  &-ubuntu::before {
    content: $icon-ubuntu;
  }
  &-windows::before {
    content: $icon-windows;
  }
  &-android::before {
    content: $icon-android;
  }
  &-arrow-down::before {
    content: $icon-arrow-down;
  }
}
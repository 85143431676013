@use "./colors" as c;
// TODO Replace with self-hosted fonts on nginx
// TODO: Make sure we preload these fonts and other needed resources
// https://developer.mozilla.org/en-US/docs/Web/HTML/Link_types/preload
// @import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');
// @import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&display=swap');
// @import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@700&display=swap');
:root,html {
    font-family: "Open Sans", sans-serif;
    font-size: min(max(16px, 4vw), 20px);
    line-height: 1.35;
    letter-spacing: 0.02em;
    color: c.color("text", "regular");
}
.lookLikeLink {
    // This exists for accessibility - having empty links is bad, so this is for use with <button>s.
    @extend a;
    padding: 0;
    margin: 0;
    border: none;
    background: none;
    font: inherit;
    color: c.color("primary", "regular");
    cursor: pointer;
}
a {
    &:link {
        color: c.color("primary", "regular");
    }
    &:visited {
        color: c.color("primary", "dark");
    }
    &:active {
        color: c.color("primary", "light");
    }
}
//Rules for users with mice
//TODO move all :hovers here
@media only screen and (any-pointer: fine) {
    //For touchscreen devices with no hover, always show underlines
    a:link {
        text-decoration: none;
    }
    a:hover {
        text-decoration: underline;
        //TODO Replace this with fancy animation,
        //which will be removed by reduced motion rule
    }
    .no-decorate {
        &, &:hover {
            text-decoration: none;
        }
    }
    a.always-decorate {
        text-decoration: underline;
    }
}


h1, h2, h3
.h1,.h2,.h3 {
    font-family: "Inter", sans-serif;
}
h4, h5, h6,
.h4,.h5,.h6 {
    font-family: "Open Sans", sans-serif;
}

.h1, h1 {
    font-weight: 600;
    font-size: 2.5rem;
    color: c.color("primary", "regular");
}

.h2, h2 {
    font-weight: 600;
    font-size: 2rem;
}

.h3, h3 {
    font-weight: 400;
    font-size: 1.8rem;
}
.h3--bold {
    @extend .h3;
    font-weight: 700;
}

.h4, h4 {
    font-size: 2rem;
}

.h5 ,h5 {
    font-size: 1.75rem;
}
.h5--bold {
    @extend .h5;
}
.h6, h6 {
    font-size: 1.35rem;
}
.h6--bold {
    @extend .h6;
    font-weight: 700;
}

.text {
    &--primary {
        font-size: 1rem;
        font-family: 'Open Sans', sans-serif;
        font-weight: normal;
    }
    &--mono {
        font-family: 'Roboto Mono', monospace;
        font-weight: 700;
    }
    &--secondary {
        font-size: 0.9rem; //TODO this dips below 16px but w/e
        font-weight: 500;
        color: c.color("text", "light");
        &--mono {
            @extend .text--secondary;
            font-family: 'Roboto Mono', monospace;
        }
    }
    &--success {
        color: c.color("primary", "regular");
    }
    &--caution {
        color: c.color("yellow", "regular");
    }
    &--danger {
        color: c.color("red", "regular");
    }
}

@use '../../atoms/atoms';
.Nav {
    // position: fixed;
    position: absolute; //Needed for dropdowns to work properly, if the nav isn't position: fixed
    top: 0; left: 0;
    width: 100%; height: 3.5rem; //For some reason width: 100% leaves a wierd gap on the right side when Inspect Element is open
    //Edit: The gap was actually the scrollbar caused by the overflow-y: scroll on the mobile nav. Resolved by moving that declaration inside .expanded
    z-index: 3;
}
nav, .Dropdown {
    height: 100%;
}
nav {
    //This element is only even here because putting it on .Nav fucks up stacking
    //https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_Positioning/Understanding_z_index/The_stacking_context
    background-color: atoms.color("secondary", "regular");
}
nav, .Nav-items {
    @include atoms.flex(row, nowrap, flex-start);
}
.Nav-items {
    list-style: none;
}
.Nav-item {
    &--logo, &--home, &--login {
        @extend .Nav-item;
    }
    &--logo {
        display: inline;
    }
    &--hamburger {
        display: none;
        font-size: 2.25em;
        cursor: pointer;
    }
}

@media screen and (min-width: 1920px) {
    .Nav-items {
        position: relative;
    }
    .Nav-item {
        &--logo {
            position: absolute;
            width: max-content;
            right: 100%;
        }
    }
}
@media screen and (min-width: 1280px) {
    .Nav-item {
        &--logo {
            margin-right: 16px;
        }
        &--login {
            margin-left: auto;
        }
        &--home {
            padding-right: 1ch;
        }
    }
}
@media only screen and (max-width: 1280px) {
    //For some reason it's being dumb as hell about height. This is sort of a dirty fix of separate values for mobile instead of making it really responsive
    .Nav {
        height: auto; max-height: 100vh;
        nav, .Nav-items {
            display: block;
            min-height: 3.5rem;
        }
        &-item {
            &--logo, &--home, &--login {
                margin-left: 16px;
            }
            &--home, &--login {
                line-height: 2.5rem;
            }
            &--logo, &--hamburger {
                line-height: 3.5rem;
            }
            &--hamburger {
                display: inline-block;
                position: absolute;
                top: 0; right: 1rem;
            }
        }
        &.expandable .Nav-items {
            @include atoms.expandable();
        }
        &.expanded .Nav-items {
            overflow-y: scroll;
            max-height: 100vh;
        }
    }
}